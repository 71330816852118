import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import {usePageContext} from "@alterpage/gatsby-plugin-alterpress-page-creator/dist/page-context";

import {wrapper, mainPart} from './hint-box.module.scss';

import BulbIcon from '../../assets/images/svg/bulb.svg';

import {IQueryAllResult} from "../../models/query-all-result.model";
import {IPage} from "../../models/page.model";
import {getNodes} from "../../utils/get-nodes";
import useTranslations from "../../hooks/use-translations";

interface IHintBoxQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale' | 'type'>>;
}

const HintBox = () => {
    const { locale } = usePageContext();
    const { allPage } = useStaticQuery<IHintBoxQueryResult>(query);
    const t = useTranslations('HintBox');

    const localizedFAQPathname = getNodes(allPage).find(
        (page) => page.locale === locale && page.type === 'candidate-faq'
    )?.pathname;

    if(!localizedFAQPathname) return null;

    return (
        <div className={wrapper}>
            {/*<div className={whitePart}/>*/}
            <div className={mainPart}>
                <BulbIcon/>
                <span>{t.plainText}&nbsp;<b><Link to={localizedFAQPathname}>{t.linkText}</Link></b></span>
            </div>
            {/*<div className={whitePart}/>*/}
        </div>
    )
}

export default HintBox;

const query = graphql`
    query {
        allPage(
            filter: { type: { in: ["candidate-faq"] } }
        ) {
            edges {
                node {
                    locale
                    type
                    pathname
                }
            }
        }
    }
`;