import React from 'react';

import { list } from './category-chip-list.module.scss';
import { IPostCategory } from '../../models/post-category.model';
import { IProductCategory } from '../../models/product-category.model';

import CategoryChip from '../atoms/category-chip';

interface ITagChipListProps {
    className?: string;
    categories: IPostCategory[] | IProductCategory[];
}

const TagChipList: React.FC<ITagChipListProps> = ({ className = '', categories }) => {
    return (
        <ul className={`${className} ${list}`}>
            {categories.map((category) => {
                return (
                    <li key={`category-item-${category.categoryId}`}>
                        <CategoryChip
                            name={category.title ? category.title : ''}
                            pathname={category.pathname}
                        />
                    </li>
                ); // TODO: FIX TITLE (REMOVE)
            })}
        </ul>
    );
};

export default TagChipList;
