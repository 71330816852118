import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { searchInputWrapper, searchInput, inputButton } from './search-input.module.scss';
import SearchIcon from '../../assets/images/svg/search.svg';
import useTranslations from '../../hooks/use-translations';

export interface ISearchInputControl {
    clear: () => void;
}

export interface ISearchInputProps {
    className?: string;
    placeholder?: string;
    initialValue?: string;
    onSearch?(value: string, parentEvent?: string): void;
    searchOnFocus?: boolean;
    buttonRef?: React.Ref<HTMLButtonElement>;
    controlRef?: React.Ref<ISearchInputControl>;
}

const SearchInput: React.ForwardRefRenderFunction<HTMLInputElement, ISearchInputProps> = (
    {
        className = '',
        placeholder = '',
        onSearch,
        initialValue = '',
        searchOnFocus = false,
        buttonRef,
        controlRef,
    },
    ref
) => {
    const [value, setValue] = useState(initialValue);
    const timeoutID = useRef<NodeJS.Timeout>();
    const t = useTranslations('SearchInput');

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue(event.target.value);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key !== 'Enter') return;
        search('keyDown');
    };

    const handleClick = () => {
        search('click');
    };

    const handleFocus = () => {
        search('focus');
    };

    const search = useCallback(
        (parentEvent?: string) => {
            if (typeof onSearch !== 'function') return;
            if (value.length < 3 && value.length > 0) return;

            onSearch(value, parentEvent);
        },
        [onSearch, value]
    );

    useImperativeHandle(controlRef, () => ({ clear: () => setValue('') }));

    useEffect(() => {
        timeoutID.current = setTimeout(search, 250);

        return () => {
            clearTimeout(timeoutID.current);
        };
    }, [search]);

    return (
        <div className={`${searchInputWrapper} ${className}`}>
            <button onClick={handleClick} ref={buttonRef} title={t.name} className={inputButton}>
                <SearchIcon />
            </button>
            <input
                className={searchInput}
                ref={ref}
                type="text"
                value={value}
                onChange={handleChange}
                onFocus={searchOnFocus ? handleFocus : undefined}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                autoComplete={'off'}
            />
        </div>
    );
};

export default React.forwardRef(SearchInput);
