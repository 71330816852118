// extracted by mini-css-extract-plugin
export var backgroundAnimation = "post-listing-module--backgroundAnimation--88b48";
export var card = "post-listing-module--card--95e09";
export var grid = "post-listing-module--grid--6a118";
export var item = "post-listing-module--item--4d6fd";
export var list = "post-listing-module--list--51fbf";
export var listBox = "post-listing-module--list-box--ef5f7";
export var overwrite = "post-listing-module--overwrite--459f5";
export var paginationBox = "post-listing-module--pagination-box--c6648";
export var section = "post-listing-module--section--8998d";
export var statusBox = "post-listing-module--status-box--27fef";