// extracted by mini-css-extract-plugin
export var accessibility = "champ-header-module--accessibility--dd908";
export var backgroundAnimation = "champ-header-module--backgroundAnimation--eeecf";
export var button = "champ-header-module--button--170d1";
export var container = "champ-header-module--container--d1e71";
export var fontBox = "champ-header-module--font-box--f6c3d";
export var grid = "champ-header-module--grid--8ee4f";
export var hamburgerMenuBox = "champ-header-module--hamburger-menu-box--ca0fa";
export var header = "champ-header-module--header--76f5d";
export var hintBox = "champ-header-module--hint-box--a63c1";
export var languageBox = "champ-header-module--language-box--0595d";
export var logoBox = "champ-header-module--logo-box--2c9c8";
export var logotype = "champ-header-module--logotype--b2e53";
export var mainNavBox = "champ-header-module--main-nav-box--3553e";
export var spacer = "champ-header-module--spacer--ae564";
export var userBox = "champ-header-module--user-box--db97f";