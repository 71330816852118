import React from 'react';
import { IMedia } from '@alterpage/gatsby-source-alterpress';
import { Image } from '@alterpage/gatsby-plugin-image';

import { avatar, small, medium, big } from './avatar.module.scss';
import { relations } from '../../config/relations';

type TAvatarSize = 'small' | 'medium' | 'big';

interface IAvatarProps {
    className?: string;
    size?: TAvatarSize;
    media?: IMedia[];
    relation?: number;
}

const Avatar: React.FC<IAvatarProps> = ({
    className = '',
    media,
    size = 'small',
    relation = relations.mainImage,
}) => {
    const sizeClass = sizeClasses[size as TAvatarSize];

    if (!media || media.length === 0) {
        return (
            <svg viewBox="0 0 100 100" className={`${avatar} ${sizeClass} ${className}`}>
                <rect width={100} height={100} fill="currentColor" />
            </svg>
        );
    }

    return (
        <Image
            className={`${avatar} ${className} ${sizeClass}`}
            aspectRatio={'1'}
            media={media}
            relation={relation}
        />
    );
};

const sizeClasses: Record<TAvatarSize, string> = {
    small,
    medium,
    big,
};

export default Avatar;
