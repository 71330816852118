export const endpoints = {
    contacts: {
        client: '/contacts/client',
        candidate: '/contacts/candidate',
    },
    menus: `/menus`,
    calculator: `/roi-calculator`,
    pages: '/pages?expand=sections,relatedPaths,meta',
    posts: `/posts?expand=meta,relatedPaths,media,author,author.media,tags,mainCategory&sort=-published_at`,
    postsCategories: `/posts/categories?expand=meta,relatedPaths`,
    postsTags: `/posts/tags?expand=meta,relatedPaths`,
    products: `/products?expand=media,relatedPaths,meta,manufacturer,attributes,categories,relatedProducts,relatedProducts.media,relatedProducts.manufacturer,relatedProducts.variants,variants,variants.media,options`,
    productsSearch: '/products/search?expand=media,manufacturer',
    productCategories: '/products/categories?expand=relatedPaths,meta',
    positions: '/positions',
    redirects: '/redirects',
    newsletterSubscription: '/newsletter/subscribe',
    sharedFiles: '/shared-files',
    forms: '/forms?expand=groups,groups.elements,groups.elements.options,groups.elements.options.media',
    settings: {
        theme: '/settings/theme',
        shop: '/settings/shop',
        user: '/settings/user',
    },
};
