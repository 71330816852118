import { useEffect } from 'react';

export default function useScroll(eventOnScroll: () => void) {
    useEffect(() => {
        if (!window) return;

        const onScroll = () => eventOnScroll();

        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });

        return () => window.removeEventListener('scroll', onScroll);
    }, [eventOnScroll]);
}
