// extracted by mini-css-extract-plugin
export var backgroundAnimation = "post-search-module--backgroundAnimation--e873f";
export var contentText = "post-search-module--content-text--143ce";
export var grid = "post-search-module--grid--0e170";
export var input = "post-search-module--input--34eba";
export var list = "post-search-module--list--7990b";
export var overwrite = "post-search-module--overwrite--c4214";
export var section = "post-search-module--section--abe3f";
export var subtitleText = "post-search-module--subtitle-text--b68dd";
export var tag = "post-search-module--tag--6c9b6";
export var titleText = "post-search-module--title-text--a0063";