import React, { useEffect, useMemo } from 'react';

import {
    section as sectionClass,
    overwrite,
    grid,
    list,
    item,
    card,
    paginationBox,
    listBox,
    statusBox,
} from './post-listing.module.scss';
import { ISection } from '../../models/section.model';
import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import PostCard from '../molecules/post-card';
import Pagination, { IPaginationProps } from '../molecules/pagination';
import Loader from '../atoms/loader';
import { useList } from '../../hooks/use-list';
import { IPost } from '../../models/post.model';
import { endpoints } from '../../config/endpoints';
import { IPostCategory } from '../../models/post-category.model';
import { IPostTag } from '../../models/post-tag.model';
import { IActiveFilter } from '../../models/active-filter.model';

interface IBusinessMeaning {
    id: number;
    activeFilters: IActiveFilter;
}

interface IBlogListingSection extends ISection {
    items: {
        businessMeaning: IBusinessMeaning;
    };
    extraItems: {
        categoryId?: number;
        tagId?: number;
        postCategory?: IPostCategory;
        postTag?: IPostTag;
        setBlogConfig?: any;
    };
}

interface IBlogListingProps {
    className?: string;
    section: IBlogListingSection;
    TitleTag?: React.ElementType;
    paginationProps?: IPaginationProps;
}

const PostListing: React.FC<IBlogListingProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const t = useTranslations('PostListing');
    const { style, sectionId, css, extraItems, items } = section;
    const { businessMeaning } = items;
    const { categoryId, postCategory, setBlogConfig } = extraItems;

    const additionalParams = useMemo(() => {
        const activeFilters = categoryId ? postCategory?.activeFilters : undefined;
        return getAdditionalParams(businessMeaning, categoryId, activeFilters);
    }, [businessMeaning, categoryId, postCategory?.activeFilters]);

    const {
        items: posts,
        status,
        paginationPaths,
        handleSearch,
        initialSearchValue,
    } = useList<IPost>({
        endpoint: endpoints.posts,
        additionalParams,
    });

    useEffect(() => {
        setBlogConfig({
            active: true,
            handleSearch: handleSearch,
            initialSearchValue: initialSearchValue,
        });
    }, []);

    return (
        <Section
            className={`${className} ${sectionClass} ${overwrite}`}
            classes={{
                container: grid,
            }}
            TitleTag={TitleTag}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <div className={listBox}>
                {status === 'loading' && (
                    <div className={statusBox}>
                        <Loader />
                    </div>
                )}
                {status === 'error' && (
                    <div className={statusBox}>
                        <p>{t.error}</p>
                    </div>
                )}
                {status === 'success' &&
                    (posts.length > 0 ? (
                        <ul className={list}>
                            {posts.map((post) => {
                                return (
                                    <li key={`post-card-item-${post.postId}`} className={item}>
                                        <PostCard postCard={post} className={card} />
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <div className={statusBox}>
                            <p>{t.empty}</p>
                        </div>
                    ))}
            </div>
            <Pagination className={paginationBox} paginationPaths={paginationPaths} />
        </Section>
    );
};

function getAdditionalParams(
    businessMeaning: IBusinessMeaning,
    postCategoryId?: number,
    activeFilters?: IActiveFilter
): Record<string, string> | undefined {
    if (postCategoryId && activeFilters) {
        return {
            [businessMeaning.activeFilters.paramName]: businessMeaning.activeFilters.value,
            [activeFilters.paramName]: activeFilters.value,
        };
    }
    return { [businessMeaning.activeFilters.paramName]: businessMeaning.activeFilters.value };
}

export default PostListing;
