import SectionChampHeader from '../components/sections/champ-header';
import SectionSubpageMenu from '../components/sections/subpage-menu';
import SectionPostSearch from '../components/sections/post-search';
import SectionPostListing from '../components/sections/post-listing';
import SectionFooter from '../components/sections/footer';

import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { IPageContext, usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { IPage } from '../models/page.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IPostCategory } from '../models/post-category.model';
import { ISetting } from '../models/setting.model';
import { IPostTag } from '../models/post-tag.model';
import { getNodes } from '../utils/get-nodes';
import getCustomStyleFromSetting from '../utils/get-custom-style-from-settings';
import { useList } from '../hooks/use-list';
import { isBrowser } from '../utils/is-browser';
import useTranslations from '../hooks/use-translations';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';
import ThemeStyle from '../components/atoms/theme-style';

interface IBlogProps {
    readonly data: {
        page: IPage;
        postTag: IPostTag | null;
        postCategory: IPostCategory | null;
        allThemeSetting: IQueryAllResult<ISetting>;
    };
}

interface IBlogPageContext extends IPageContext {
    tagId?: number;
    categoryId?: number;
}

const Blog: React.FC<IBlogProps> = ({ data }) => {
    const t = useTranslations('BlogTemplate');
    const { page, postTag, postCategory, allThemeSetting } = data;
    const { tagId, categoryId }: IBlogPageContext = usePageContext();
    const { sections } = page;
    const themeSettings = getNodes(allThemeSetting);
    const customStyle = getCustomStyleFromSetting(themeSettings);

    const [blogConfig, setBlogConfig] = useState({
        active: false,
        handleSearch: () => {},
        initialSearchValue: '',
    });

    const { initialSearchValue } = useList({ endpoint: null });

    const sectionsWithData = sections.map((section) => {
        if (section.type === 'post-search') {
            const sectionCategories = (section.items?.categories as IPostCategory[]) || [];
            const categoriesWithAll =
                sectionCategories.length > 0 && isBrowser() && postCategory
                    ? [
                          {
                              categoryId: 0,
                              title: t.allLabel,
                              pathname: categoryId
                                  ? window.location.pathname
                                        .slice(0, -1)
                                        .replace(postCategory.activeFilters.value, '')
                                  : window.location.pathname,
                          },
                          ...sectionCategories,
                      ]
                    : [];
            return {
                ...section,
                items: {
                    categories: categoriesWithAll,
                },
                settings: {
                    onSearch: blogConfig.handleSearch,
                    initialSearchValue: blogConfig.active
                        ? blogConfig.initialSearchValue
                        : initialSearchValue,
                },
            };
        }
        if (section.type === 'post-listing') {
            return {
                ...section,
                extraItems: {
                    categoryId: categoryId,
                    tagId: tagId,
                    postCategory: postCategory,
                    postTag: postTag,
                    setBlogConfig: setBlogConfig,
                },
            };
        }
        return section;
    });

    return (
        <MainLayout>
            <ThemeStyle style={customStyle} />
            {sectionsWithData.map((section) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!, $tagId: Int, $categoryId: Int) {
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            pathname
            type
            sections {
                ...sectionFields
            }
        }
        postTag(tagId: { eq: $tagId }, locale: { eq: $locale }) {
            tagId
            name
            activeFilters {
                paramName
                value
            }
        }
        postCategory(categoryId: { eq: $categoryId }, locale: { eq: $locale }) {
            businessMeaning
            activeFilters {
                paramName
                value
            }
        }
        allThemeSetting {
            edges {
                node {
                    name
                    value
                }
            }
        }
    }
`;

export default Blog;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'champ-header': SectionChampHeader,
    'subpage-menu': SectionSubpageMenu,
    'post-search': SectionPostSearch,
    'post-listing': SectionPostListing,
    'footer': SectionFooter,
};