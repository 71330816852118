import React from 'react';
import { Link } from 'gatsby';

import { link, active } from './category-chip.module.scss';

interface ICategoryChipProps {
    className?: string;
    name: string;
    pathname: string;
    isActive?: boolean;
}

const CategoryChip: React.FC<ICategoryChipProps> = ({
    className = '',
    name,
    pathname,
    isActive = false,
}) => {
    return (
        <Link
            to={pathname}
            className={`${className} ${link} ${isActive ? active : ''}`}
            activeClassName={active}
        >
            {name}
        </Link>
    );
};

export default CategoryChip;
