import React from 'react';

import { wrapper, link, activeLink } from './main-nav.module.scss';

import { INavLink } from '../../models/nav-link.model';

import NavLink from '../atoms/nav-link';

interface IMainNavProps {
    navLinks: INavLink[];
}

const MainNav: React.FC<IMainNavProps> = ({ navLinks }) => {
    return (
        <ul className={wrapper}>
            {navLinks &&
                navLinks.map((navLink) => {
                    return (
                        <li key={`main-nav-item-${navLink.linkId}`}>
                            <NavLink
                                navLink={navLink}
                                className={link}
                                activeClassName={activeLink}
                            />
                        </li>
                    );
                })}
        </ul>
    );
};

export default MainNav;
