import React, { useState } from 'react';

import {
    header,
    grid,
    container,
    spacer,
    logoBox,
    logotype,
    fontBox,
    languageBox,
    mainNavBox,
    userBox,
    hamburgerMenuBox,
    hintBox,
    button,
    accessibility,
} from './champ-header.module.scss';

import { ILogo } from '../../models/logo.model';
import { ISection } from '../../models/section.model';
import { INavLink } from '../../models/nav-link.model';

import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import Logo from '../atoms/logo';
import LanguageSelector from '../molecules/language-selector';
import MainNav from '../molecules/main-nav';
import HintBox from '../molecules/hint-box';
import IconButton from '../atoms/icon-button';
import IconFactory from '../hoc/icon-factory';
import UserNav from '../molecules/user-nav';
import AsideMenu from '../organisms/aside-menu';
import AccessibilityMenu from '../organisms/accessibility-menu';

export interface ISectionHeader extends ISection {
    items: {
        logo: ILogo | null;
        menuLinks: INavLink[];
        sideMenuLinks: INavLink[];
    };
}

interface IHeaderProps {
    className?: string;
    section: ISectionHeader;
}

const Header: React.FC<IHeaderProps> = ({ className = '', section }) => {
    const t = useTranslations('Header');
    const {
        style,
        items: { logo, menuLinks, sideMenuLinks },
    } = section;

    const [isAsideNavOpen, setIsAsideNavOpen] = useState(false);
    const [isAccessibilityMenuOpen, setIsAccessibilityMenuOpen] = useState(false);
    const [isAccessibilityApplied, setIsAccessibilityApplied] = useState(false);

    const handleShowNavigation = () => {
        setIsAsideNavOpen(true);
    };

    const handleCloseNavigation = () => {
        setIsAsideNavOpen(false);
    };

    const handleAccessibilityButtonClick = () => {
        setIsAccessibilityMenuOpen((state) => !state);
    };

    const closeAccessibilityMenu = () => {
        setIsAccessibilityMenuOpen(false);
    };

    const handleAnyAccessibilityOptionApplied = (isAnyApplied: boolean) => {
        setIsAccessibilityApplied(isAnyApplied);
    };

    return (
        <>
            <Section
                Tag="header"
                className={`${header} ${className}`}
                classes={{ container: container }}
                style={style}
            >
                <div className={grid}>
                    <div className={fontBox}>
                        <IconButton
                            title={t.buttons.accessibility}
                            className={`${button} ${isAccessibilityApplied ? accessibility : ''}`}
                            onClick={handleAccessibilityButtonClick}
                        >
                            <span>AAaa</span>
                        </IconButton>
                    </div>
                    <div className={languageBox}>
                        <LanguageSelector />
                    </div>
                    <div className={logoBox}>
                        {logo && <Logo className={logotype} logo={logo} />}
                    </div>
                    <div className={mainNavBox}>
                        <MainNav navLinks={menuLinks} />
                    </div>
                    <div className={userBox}>
                        <UserNav />
                    </div>
                    <div className={hamburgerMenuBox}>
                        <IconButton onClick={handleShowNavigation} title={'Menu'}>
                            <IconFactory icon="menu" />
                        </IconButton>
                    </div>
                    <div className={hintBox}>
                        <HintBox />
                    </div>
                </div>
            </Section>
            <div className={spacer} />
            <AsideMenu
                isOpen={isAsideNavOpen}
                closeFunction={handleCloseNavigation}
                generalLinks={sideMenuLinks}
            />
            <AccessibilityMenu
                isOpen={isAccessibilityMenuOpen}
                closeFunction={closeAccessibilityMenu}
                onAnyOptionApplied={handleAnyAccessibilityOptionApplied}
            />
        </>
    );
};

export default Header;
