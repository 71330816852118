import React, { useRef, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import {
    wrapper,
    button,
    text,
    icon,
    loginBox,
    loginLinks,
    dropdown,
    logoutButton,
} from './user-nav.module.scss';
import PersonIcon from '../../assets/images/svg/person.svg';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import useTranslations from '../../hooks/use-translations';
import { getNodes } from '../../utils/get-nodes';
import { useClient } from '../../hooks/use-client';
import { useCandidate } from '../../hooks/use-candidate';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import Button from '../atoms/button';
import Dropdown from '../hoc/dropdown';
import IconFactory from '../hoc/icon-factory';

interface IUserNavProps {
    className?: string;
}

interface IUserNavQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'type' | 'pathname' | 'locale'>>;
}

type TUserNavPaths = Record<
    'login-candidate' | 'login-client' | 'client-dashboard' | 'candidate-dashboard',
    string
>;

const UserNav: React.FC<IUserNavProps> = ({ className = '' }) => {
    const { locale } = usePageContext();
    const { allPage } = useStaticQuery<IUserNavQueryResult>(query);
    const t = useTranslations('UserNav');
    const buttonRef = useRef<HTMLElement>(null);
    const client = useClient();
    const candidate = useCandidate();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const isLoggedIn = client.isLoggedIn || candidate.isLoggedIn;
    const pages = getNodes(allPage).filter((page) => locale === page.locale);
    const paths = Object.fromEntries(
        pages.map((page) => [page.type, page.pathname])
    ) as TUserNavPaths;

    const handleToggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    const handleCloseDropdown = () => {
        setIsDropdownOpen(false);
    };

    const handleLogout = () => {
        if (candidate.isLoggedIn) {
            candidate.logout.fetch();
        }
        if (client.isLoggedIn) {
            client.logout.fetch();
        }
    };

    return (
        <div className={`${wrapper} ${className}`}>
            {!isLoggedIn && (
                <>
                    <div className={loginBox}>
                        <Button
                            ref={buttonRef}
                            className={button}
                            stylePreset={'secondary'}
                            onClick={handleToggleDropdown}
                        >
                            <span className={text}>{t.login}</span>
                            <PersonIcon className={icon} />
                        </Button>
                        <Dropdown
                            className={dropdown}
                            isOpen={isDropdownOpen}
                            closeFunction={handleCloseDropdown}
                            buttonRef={buttonRef}
                        >
                            <div className={loginLinks}>
                                <Link to={paths['login-candidate']}>{t.loginCandidate}</Link>
                                <Link to={paths['login-client']}>{t.loginClient}</Link>
                            </div>
                        </Dropdown>
                    </div>
                    <Button
                        as="link"
                        className={button}
                        stylePreset={'cta'}
                        to={paths['login-client']}
                    >
                        <span className={text}>{t.addJobOffer}</span>
                        <IconFactory icon="plus" className={icon} />
                    </Button>
                </>
            )}
            {isLoggedIn && (
                <>
                    <Button
                        className={button}
                        stylePreset={'secondary'}
                        as="link"
                        to={
                            client.isLoggedIn
                                ? paths['client-dashboard']
                                : paths['candidate-dashboard']
                        }
                    >
                        <span className={text}>{t.account}</span>
                        <PersonIcon className={icon} />
                    </Button>
                    <button className={`${logoutButton} ${button}`} onClick={handleLogout}>
                        <span className={text}>{t.logout}</span>
                        <IconFactory icon="logout" className={icon} />
                    </button>
                </>
            )}
        </div>
    );
};

const query = graphql`
    query {
        allPage(
            filter: {
                type: {
                    in: [
                        "login-candidate"
                        "login-client"
                        "client-dashboard"
                        "candidate-dashboard"
                    ]
                }
            }
        ) {
            edges {
                node {
                    type
                    locale
                    pathname
                }
            }
        }
    }
`;

export default UserNav;
